import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  metadata: {
    title: "API CobCloud"
  },
  page:{
    logoUrl: "http://www.cobcloud.com.br/logo-cobcloud2.png",
    /*
    banner: {
      message: "Exemplo de banner",
      color: "info"
    }
    */
  },
  topNavigation: [
    { id: "docs", label: "Guias" },
    { id: "api", label: "API Referência" },
  ],
  sidebar: {
    docs: [
      {
        type: "category",
        label: "INTRODUÇÃO",
        icon: "sparkles",
        items: [
          {
            type: "doc",
            label: "Introdução",
            id: "intro",
            icon: "book-text"
            //badge: {label: "1", color: "indigo"},
          },
          {
            type: "doc",
            label: "Autenticação",
            id: "auth",
            icon: "lock-keyhole"
          },
          {
            type: "doc",
            label: "Collections",
            id: "collections",
            icon: "book-marked"
          },
          {
            type: "doc",
            label: "Paginação",
            id: "page",
            icon: "file-digit"
          },
          {
            type: "doc",
            label: "Limites da API",
            id: "limit",
            icon: "ellipsis"
          },
          {
            type: "doc",
            label: "Suporte",
            id: "suporte",
            icon: "message-circle-code"
          }
        ],
      },
    ],
  },
  redirects: [
    { from: "/", to: "/docs/intro" },
    { from: "/docs", to: "/docs/intro" },
  ],
  apis: {
    type: "file",
    input: "./apis/doc-openapi.yaml",
    //type: "url",
    //input: "https://api-v3.cob.cloud/openapi",
    navigationId: "api",
  },
  docs: {
    files: "/pages/**/*.{md,mdx}",
  }
};

export default config;
